import React, { useState, useRef, useContext } from "react";
import { connect } from "react-redux";
import '../../../Assets/Css/BasicSearchScreen.css';
import { useNavigate, useLocation } from "react-router-dom";
import {
    Alert,
    AlertTitle,
    Box,
    Modal,
    Grid,
    Button
} from "@mui/material";
import { emptyObject } from "../../../Utils/Common/common.util"
import { inputAction } from "../../../Redux/Actions/Common/inputAction"
import { membershipSearchAction } from "../../../Redux/Actions/Membership/memberSearchAction"
import { ROUTES } from "../../../Constants/routesConstant";
import { RESTRICT_FOR_NUMBER_TEXTBOX, SSN_ALLOWED_KEYS } from "../../../Constants/widgetConstant";

const PayerFileSearch = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({ firstName: '', lastName: '', payerId: '', SSN: '', alternamteMemberId: '' });
    const [errorMsg, setErrorMsg] = useState('');
    const FirstNameRef = useRef(null);
    const LastNameRef = useRef(null);
    const PayerIdRef = useRef(null);
    const SSNRef = useRef(null);
    const AlternateMemberIdRef = useRef(null);
    const SearchButtonRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'SSN'){
            handleSSNInput(e);
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
        setErrorMsg("");
    };
    const handlePayerFileSearch = async (e) => {
        e.preventDefault();
        const { firstName, lastName, payerId, SSN, alternateMemberId } = formData;
        if (!payerId || !(lastName || firstName || SSN || alternateMemberId)) {
            setErrorMsg("Payer Id and an additional field are mandatory to see file search data.");
            return;
        }
        props.input({ ...props.component_input, 'payerFileSearch': formData })
        if (props?.component_input?.search && !emptyObject(props?.component_input?.search)) {
            const searchQuery = {};
            if (firstName) {
                searchQuery['firstName'] = firstName;
            }
            if (lastName) {
                searchQuery['lastName'] = lastName;
            }
            if (payerId) {
                searchQuery['payerId'] = payerId;
            }
            if (SSN) {
                searchQuery['SSN'] = SSN?.replace(/-/g, "");
            }
            if (alternateMemberId) {
                searchQuery['alternateMemberId'] = alternateMemberId;
            }
            await props.getSearchData(searchQuery)
            navigate(`${ROUTES.FILE_SEARCH}`, { state: { from: location.pathname } });
        }
    };

    const handleTabNavigation = (e, ref) => {
        if (e.key === 'Tab' && ref.current === document.activeElement) {
            e.preventDefault();
            if (ref === PayerIdRef) {
                SearchButtonRef.current.focus();
            } else {
                ref.current.focus();
            }
        }
    };

    const handleSSNInput = (e) => {
        const allowedKeys = SSN_ALLOWED_KEYS;

        if(e.nativeEvent.data && !allowedKeys.includes(e.nativeEvent.data)) {
            e.preventDefault();
            return;
        }

        let value = SSNRef.current.value.replace(/\D/g, '');
        let name = e.target.name;
        if (value.length > 3 && value.length <= 5) {
            value = `${value.slice(0,3)}-${value.slice(3)}`;
        } else if(value.length > 5){
            value = `${value.slice(0,3)}-${value.slice(3, 5)}-${value.slice(5, 9)}`;
        }
        setFormData((prev) => ({ ...prev, [name]: value}));
    };

    return (
        <>
            <div className="BasicSearch">
                <div className="Basicsearchform">
                    <div className="form-group">
                        <label htmlFor="payerId">Payer ID</label>
                        <input
                            type="number"
                            pattern="\d{0,7}"
                            placeholder="Enter Payer Id"
                            name="payerId"
                            value={formData.payerId}
                            onChange={(e)=> {
                                const value = e.target.value;
                                if(value.length <= 7) {
                                    handleChange(e);
                                }
                            }}
                            ref={FirstNameRef}
                            maxLength={7}
                            onKeyDown={(e) => {
                                    if (RESTRICT_FOR_NUMBER_TEXTBOX.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                    handleTabNavigation(e, FirstNameRef)
                                }
                            }
                        />
                    </div>

                    <div className="form-group">
                        <p>First name(optional)</p>
                        <input
                            type="text"
                            placeholder="Enter first name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            ref={FirstNameRef}
                            onKeyDown={(e) => handleTabNavigation(e, LastNameRef)}
                        />
                    </div>

                    <div className="form-group">
                        <p>Last name(optional)</p>
                        <input
                            type="text"
                            placeholder="Enter last name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            ref={LastNameRef}
                            onKeyDown={(e) => handleTabNavigation(e, SSNRef)}
                        />
                    </div>

                    <div className="form-group">
                        <p>Social Security number(optional)</p>
                        <input
                            type="text"
                            placeholder="Enter Social Security Number"
                            name="SSN"
                            value={formData.SSN}
                            onChange={handleChange}
                            ref={SSNRef}
                            onKeyDown={(e) => {
                                    handleTabNavigation(e, AlternateMemberIdRef);
                                }
                            }
                        />
                    </div>

                    <div className="form-group">
                        <p>Alternate Member Id(optional)</p>
                        <input
                            type="text"
                            placeholder="Enter Alternate Member Id"
                            name="alternateMemberId"
                            value={formData.alternateMemberId}
                            onChange={handleChange}
                            ref={LastNameRef}
                            maxLength={15}
                            onKeyDown={(e) => {   
                                    handleTabNavigation(e, SearchButtonRef)
                                }
                            }
                        />
                    </div>
                    <Grid item xs={12} className="eligibility-error-btn">
                        <Button className={`view-all-error`} variant="contained" color="success" onClick={handlePayerFileSearch} ref={SearchButtonRef}>
                            Search
                        </Button>
                    </Grid>
                    {errorMsg && (
                        <div className="pfserrmsg" id="errormsgs">
                            <Alert severity="error"
                                onClose={() => setErrorMsg(null)}>
                                <AlertTitle>Error</AlertTitle>
                                {errorMsg}
                            </Alert>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    input: inputAction,
    getSearchData: membershipSearchAction
};

export default connect(mapStateToProps, mapActionToProps)(PayerFileSearch);