import React, {useState, useEffect, useRef} from "react";

const UserNameRenderer = (params) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])
    const handleUserField = (e, params) => {
        const value = e.target.value;
        params.setValue(e.target.value)
        return;
    }

    const handleKeyDown = (e) => {
        if (e.key === "Backspace" || e.key === "Delete") {
            e.stopPropagation();
        }
    }

    const showValidation = (!params.value || params.value.trim() === '');

    useEffect(() => {
        params?.handleUserValidation(showValidation)
    }, [showValidation])

    if (params.isEditable) {
        return (<div style={{ width: '100%', height: '100%'}}>
            <input 
                type="text"
                value={params.value || ''}
                onChange={(e) => handleUserField(e, params)}
                onKeyDown={handleKeyDown}
                style={{ width: '90%', height: '60%', border: showValidation ? '2px solid red' : ''}}
            />
            {showValidation && <div style={{color: 'red', fontSize: '12px'}}>Please enter User Name</div>}
        </div>)
    } else {
        return (<>{params.data.Name}</>)
    }
}
export default UserNameRenderer;