import BasicSearchScreen from '../Component/Widget/MembershipSearch/BasicSearchScreen';
import MembershipToErrorRatio from '../Component/Widget/MembershipToErrorRatio/MembershipToErrorRatio';
import HelpfulLinks from '../Component/Widget/HelpfulLink/HelpfulLinks';
import AdminAndSecuritySetup from '../Component/Widget/AdminAndSecuritySetup/AdminAndSecuritySetup';
import EligibilityErrors from '../Component/Widget/EligibilityErrors/EligibilityErrors';
import EligibilityErrorsAndRejections from '../Component/Widget/EligibilityErrors/EligibilityErrorsAndRejections';
import EligibilityErrorsInProgress from '../Component/Widget/EligibilityErrorsInProgress/EligibilityErrorsInProgress';
import EligibilityMaintenance from '../Component/Widget/Eligibility Maintenance/EligibilityMaintenance';
import EligibilityErrorsTrends from '../Component/Widget/EligibilityErrorsTrends/EligibilityErrorsTrends';
import PayerEligibilityFileTracking from '../Component/Widget/PayerEligibilityFileTracking/PayerEligibilityFileTracking';
import PayerFileSearch from '../Component/Widget/PayerFileSearch/PayerFileSearch';
import UnassignedErrors from '../Component/Widget/UnAssignedErrors/UnAssignedErrors';
import { ROUTES } from '../Constants/routesConstant';

const WIDGET_CONSTANT = {
  BasicSearchScreen: {
    component: BasicSearchScreen,
    title: 'Eligibility Search',
    mtd: false,
    subTitle:
      'You must enter  a first and last name to start search. Additional search options are available in advanced search link.',
    hideHeader: false,
    icon: 'InfoIcon',
  },
  MembershipToErrorRatio: {
    component: MembershipToErrorRatio,
    componentName: 'MembershipToErrorRatio',
    title: 'Payer File Error Ratio',
    subTitle: '',
    mtd: true,
    urlTitle: 'memberErrorRatio',
    mtdItem: '',
  },
  HelpfulLinks: {
    component: HelpfulLinks,
    title: 'Helpful Links',
    mtd: false,
    subTitle: 'Link to other applications',
    links: [
      {
        url: '#',
        text: 'Group Configurator',
        className: '',
      },
      {
        url: '#',
        text: 'Enrollment Suite',
        className: '',
      },
      {
        url: '#',
        text: 'Product Enrollment Toolkit (PET)',
        className: '',
      },
      {
        url: '#',
        text: 'ORMB',
        className: '',
      },
      {
        url: '#',
        text: 'Tableau Reporting',
        className: '',
      },
    ],
  },
  AdminAndSecuritySetup: {
    component: AdminAndSecuritySetup,
    componentName: 'AdminAndSecuritySetup',
    title: 'Admin And Security Setup',
    mtd: false,
    subTitle: 'Content around admin And security setup',
    links: [
      {
        url: ROUTES.ADMIN_SECURITY,
        text: 'Modify Settings',
        className: 'widget-anchor',
      },
    ],
    icon: 'LockOutlinedIcon',
  },
  EligibilityErrors: {
    component: EligibilityErrors,
    componentName: 'EligibilityErrors',
    title: 'Eligibility Errors',
    mtd: true,
    subTitle: '',
    urlTitle: 'eligibilityErrors',
    mtdItem: '',
  },
  EligibilityErrorsAndRejections: {
    component: EligibilityErrorsAndRejections,
    componentName: 'EligibilityErrorsAndRejections',
    title: 'Eligibility Errors with Rejections',
    mtd: true,
    subTitle: '',
    urlTitle: 'eligibilityErrors',
    mtdItem: '',
  },
  EligibilityErrorsInProgress: {
    component: EligibilityErrorsInProgress,
    title: 'Assigned Eligibility Errors in Progress',
    mtd: false,
    subTitle: '',
    links: [
      {
        url: '#',
        text: 'American Republic Insurance Company',
        className: 'widget-anchor',
        value: 3,
      },
      {
        url: '#',
        text: 'Group Administrators, LTD.',
        className: 'widget-anchor',
        value: 2,
      },
      {
        url: '#',
        text: 'Lucent Health California Division',
        className: 'widget-anchor',
        value: 2,
      },
      {
        url: '#',
        text: 'Allegiance Benefit Plan Management, INC.',
        className: 'widget-anchor',
        value: 2,
      },
      {
        url: '#',
        text: 'The Lewer Agency, INC',
        className: 'widget-anchor',
        value: 1,
      },
      {
        url: '#',
        text: 'Capitol Administrators SE',
        className: 'widget-anchor',
        value: 1,
      },
      {
        url: '#',
        text: 'Healthscope Benefits',
        className: 'widget-anchor',
        value: 1,
      },
    ],
  },
  EligibilityMaintenance: {
    component: EligibilityMaintenance,
    title: 'Eligibility Maintenance',
    mtd: false,
    subTitle: 'Content around adding new mwmbers or updating existing Members.',
    links: [
      {
        url: '#',
        text: 'Eligibility Maintenance',
        className: 'widget-anchor',
      },
    ],
    icon: 'ManageAccountsOutlinedIcon',
  },
  EligibilityErrorsTrends: {
    component: EligibilityErrorsTrends,
    componentName: 'EligibilityErrorsTrends',
    title: 'Eligibility Errors Trends',
    mtd: true,
    subTitle: '',
    totalError: 'Total Errors',
    urlTitle: 'eligibilityErrorsTrends',
    mtdItem: '',
  },
  PayerEligibilityFileTracking: {
    component: PayerEligibilityFileTracking,
    title: 'Payer Eligibility File Tracking',
    mtd: false,
    subTitle: 'Content around eligibility file tracking',
    links: [
      {
        url: '#',
        text: 'Group Administrators, LTD.',
        className: 'widget-anchor',
        value: 2,
      },
      {
        url: '#',
        text: 'The Lewer Agency, INC',
        className: 'widget-anchor',
        value: 2,
      },
      {
        url: '#',
        text: 'Lucent Health California Division',
        className: 'widget-anchor',
        value: 1,
      },
    ],
  },
  PayerFileSearch: {
    component: PayerFileSearch,
    title: 'Payer File Search',
    mtd: false,
    subTitle:
      'You must enter 2 fields one is Payer Id and any other optional field',
  },
  UnAssignedErrors: {
    component: UnassignedErrors,
    title: 'Unassigned Errors',
    mtd: false,
    subTitle: '17 fallouts need to be addressed.',
    errors: 17,
  },
};
export const WIDGET_LIST = Object.keys(WIDGET_CONSTANT);

export const SSN_ALLOWED_KEYS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '-',
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'Tab',
];

export const RESTRICT_FOR_NUMBER_TEXTBOX = ['E', 'e', '.', '-', '+'];
export const MTD_ITEM = ['Today', 'LBD', 'MTD', 'PM', 'QTD', 'PQ', 'YTD'];
export const EXCLUDE_WIDGET = [];

export const isWidget = (componentName) => {
  return componentName &&
    WIDGET_CONSTANT[componentName] &&
    !EXCLUDE_WIDGET.includes(componentName)
    ? true
    : false;
};
export const ADMIN_SECURITY_SETUP_WIDGET = 'AdminAndSecuritySetup';

export default WIDGET_CONSTANT;
