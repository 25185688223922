import React from "react"
import { Grid, Button } from "@mui/material";

const EligibilityErrorsInProgress = (props) => {
    const { links } = props;
    return (<>
     <Grid item xs={11.5} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item style={{ fontWeight: 'bold', textalign: 'left' }}>Total errors</Grid>
            <Grid item className={`text-right`} style={{ fontWeight: 'bold', paddingBottom: '10px' }}>12</Grid>
        </Grid>
        {links.map(link => {
            return (
                <Grid container>
                    <Grid item className={`error-container widget-anchor common-line`} xs={11}>
                        <a href="#"
                            className={`widget-anchor`}>
                            {link.text}</a>
                    </Grid>
                    <Grid item className={`text-right error-container common-line`} xs={1}>{link.value}</Grid>
                </Grid>
            )
        })}
    </>)
}

export default EligibilityErrorsInProgress;