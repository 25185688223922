import React from "react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Grid, Typography } from "@mui/material";
import { COMMON_CLASS } from "../../../Constants/constants"

const MembershipGrid = ({
    onGridReady,
    data = [],
    title = '',
    column = [],
    gridRef,
    defaultCofDef,
    domLayout = 'normal'
}) => {
    const { AG_THEME_ALPINE } = COMMON_CLASS
    return (
        <div className="subscriber-info-box container">
            <Grid xs={12}>
                <Typography
                    sx={{ marginBottom: '8px', marginLeft: '5px' }}
                    className="user-title-left">
                    {title || ''}
                </Typography>
                <div
                    className={`${AG_THEME_ALPINE} productmodal-grid`}
                    style={{
                        height: `${domLayout === 'normal' ? '100px' : '175px'}`,
                        marginLeft: '-8px'
                    }}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={data || []}
                        columnDefs={column}
                        defaultColDef={defaultCofDef}
                        ref={(grid) => { gridRef.current = grid }}
                        rowHeight={50}
                        overlayNoRowsTemplate={`No ${title ? title : 'data'} available`}
                    />
                </div>
            </Grid>
        </div>
    )
}

export default MembershipGrid