import React from "react";
import { Grid, Typography } from "@mui/material";
import { underscoreToFirstCharCapital, addSpaceBeforeCaps } from "../../../Utils/Common/common.util";
import "../../../Assets/Css/UserInformationScreen.css";
import { CAPS_TO_SPACE, REPLACE_WORD } from "../../../Constants/constants"

const UserInformation = (props) => {
  const { userData, excludeKey, accordian = false, title, noBox } = props;

  const updateShowKey = (str = '') => {
    return str.split(' ').map(word => {
      if (Object.keys(REPLACE_WORD).indexOf(word) > -1) return REPLACE_WORD[word]
      else return word
    }).join(' ')
  }

  return (
    <div className={!accordian ? (noBox ? 'no-box' : 'container subscriber-container') : ''}>
      {!accordian && <Typography sx={{ marginBottom: '8px' }} className="user-title-left">{title}</Typography>}
      <Grid className={!accordian ? (noBox ? 'user-info-content no-box-grid' : 'user-info-content') : ''} container spacing={1}>
        {Object.keys(userData || {}).map((memberItem, i) => {
          return (
            <React.Fragment key={`user-data-${memberItem}--${i}`}>
              {(excludeKey || []).indexOf(memberItem) === -1 && typeof userData[memberItem] === "string" ? (
                <Grid
                  className={`pd-20 ${noBox ? 'no-box-grid-item' : ''}`}
                  item
                  xs={6} sm={4} md={3}
                >
                  <div className="user-info-label">
                    {CAPS_TO_SPACE.indexOf(memberItem) === -1 ?
                      updateShowKey(addSpaceBeforeCaps(underscoreToFirstCharCapital(memberItem))) :
                      updateShowKey(underscoreToFirstCharCapital(memberItem))}
                  </div>
                  <div>{userData[memberItem]}</div>
                </Grid>
              ) : null}
            </React.Fragment>
          );
        })}
      </Grid>
    </div>
  );
};

export default UserInformation;