import React from 'react';
import {
    Modal,
    Box,
    Typography,
    Grid,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { connect } from "react-redux";
import Icon from "../../Common/Icon"
import {
    PRODUCT_MODAL,
    colDef,
    gridOptions,
    defaultCofDef
} from "../../../Constants/ComponentConstants/MembershipConstants/DetailsConstants/productModalConstants"
import { modalStyle } from "../../../Constants/constants"
import Loading from '../../Common/Loading';
import { getMembershipProductAction } from '../../../Redux/Actions/Membership/getMembershipProductAction';
import { emptyObject } from "../../../Utils/Common/common.util"
import {getMemberProduct} from "../../../Utils/Membership/Details/product.util"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const productModalStyle = { ...modalStyle, width: 1300 }

const ProductModal = (props) => {
    const { open, close, selectedUser, getProduct, member_product } = props
    const [showLoading, setShowLoading] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('')
    const [products, setProducts] = React.useState([])
    const productPayload = selectedUser?.primaryUser ? selectedUser?.primaryUser : selectedUser
    const { TITLE: { PRODUCT_LIST } } = PRODUCT_MODAL
    const gridRef = React.useRef(null);
    const handleClose = () => { close(false); }
    React.useEffect(() => {
        setErrorMsg('')
        if (productPayload && !emptyObject(productPayload)) {
            setShowLoading(() => {
                if (member_product?.length > 0) return false
                else return true
            })
            getProduct(productPayload)
        }
    }, [productPayload?.membershipId])

    React.useState(() => {
        setShowLoading(() => { return false })
        if (member_product?.error)
            setErrorMsg(member_product?.error)
        else {
            setProducts([...[], ...getMemberProduct(member_product)])
            if (member_product?.length === 0) setErrorMsg('No Product')
            else {
                setErrorMsg('')
            }
        }
    }, [member_product])

    const onGridReady = (params) => {
        gridRef.current = params.api;
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={productModalStyle}>
                    <Grid container spacing={2}>
                        <Grid xs={11}>
                            <Typography id="modal-title" variant="h6" component="h6">
                                <b>{PRODUCT_LIST}</b>
                            </Typography>
                        </Grid>
                        <Grid style={{ textAlign: 'right', cursor: 'pointer' }} xs={1}>
                            <Icon name="CloseIcon" onClick={handleClose} />
                        </Grid>
                        <Grid xs={12}>
                            {
                                open && products?.length > 0 && !member_product?.error ?
                                    <div className="ag-theme-alpine productmodal-grid" style={{ minHeight: 300 }}>
                                        <AgGridReact
                                            onGridReady={onGridReady}
                                            rowData={products}
                                            columnDefs={colDef}
                                            domLayout='autoHeight'
                                            gridOptions={gridOptions}
                                            defaultColDef={defaultCofDef}
                                            ref={(grid) => { gridRef.current = grid }}
                                        />
                                    </div>
                                    : showLoading ?
                                        <Loading /> :
                                        errorMsg ? { errorMsg } :
                                            <></>
                            }

                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    getProduct: getMembershipProductAction
};


export default connect(mapStateToProps, mapActionToProps)(ProductModal);
