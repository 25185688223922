import React from "react"
import DraggableCard from "./DraggableCard"
import { isWidget } from "../../Constants/widgetConstant"
const GridContainer = (props) => {
    const { currentElement, currIndex, idStartWith, i, setMouseLocation } = props
    return (
        isWidget(currentElement?.component) && (currIndex % 3 === i)
            ?
            <div
                key={`${idStartWith}-draggableItem-${currIndex}-${JSON.stringify(currentElement)}`}
                id={`${idStartWith}-${currIndex}-grid`}
                className={`draggable-container-grid current-index-${currIndex}`}
                onMouseOver={() => setMouseLocation({ index: currIndex, position: idStartWith })}
            >
                <DraggableCard
                    {...props}
                />
            </div>
            : <></>
    )
}

export default GridContainer