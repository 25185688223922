import React, { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import { fileDetailsAction } from "../../../Redux/Actions/Widgets/fileTrackingAction"
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import {
    TITLE,
    FILE_DETAILS_COLUMN,
    CLASS_NAME,
    TEXT
} from "../../../Constants/ComponentConstants/WidgetConstants/PayerEligibilityFileTrackingConstants/fileTrackingConstants"
import { COMMON_CLASS } from "../../../Constants/constants"
import { Grid, Typography } from "@mui/material";
import '../../../Assets/Css/FileTracking.css'
import Icon from "../../Common/Icon"
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router-dom';
import {ROUTES} from "../../../Constants/routesConstant"
import {camelCaseToWords, emptyObjectWithEmptyKeys} from "../../../Utils/Common/common.util"

const Inputfilesearchresults = (props) => {
    const [rowData, setRowData] = useState([]);
    const { getData, file_details: { fileDetails } } = props
    const { AG_THEME_ALPINE, ML_30 } = COMMON_CLASS
    const { INPUT_FILE_SEARCH_RESULTS, FILE_DETAILS } = TITLE
    const { LINE, FILE_DETAILS_GRID_CLASS, FILE_SEARCH_TITLE, FILE_DETAILS_DOWNLOAD, FILE_SEARCH_RESULT } = CLASS_NAME
    const { DOWNLOAD_RESULTS } = TEXT
    const {DASHBOARD} = ROUTES
    const gridRef = useRef(null)
    const location = useLocation();
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setRowData(fileDetails)
    }, [fileDetails])

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography className={`${FILE_SEARCH_TITLE} ${ML_30}`} variant="h5">
                        {location?.state?.from === DASHBOARD ? `${INPUT_FILE_SEARCH_RESULTS}` : `${FILE_DETAILS}`}
                    </Typography>
                </Grid>
                <div className={LINE}></div>
                <Grid item xs={10}>
                {location?.state?.from === DASHBOARD ? 
                !emptyObjectWithEmptyKeys(props?.component_input?.payerFileSearch) ?
                <p className={`${FILE_SEARCH_RESULT}`}>
                {`Showing result for `}
                {Object.keys(props?.component_input?.payerFileSearch).map((searchKey,i)=>{
                    return <>{props?.component_input?.payerFileSearch[searchKey] ? 
                        ` ${camelCaseToWords(searchKey)}: ${props?.component_input?.payerFileSearch[searchKey]}` 
                        : ''}</>
                })}
                </p>
                 : ''
                : ''}
                </Grid>
                <Grid item xs={2}>
                    <IconButton
                    onClick={()=>gridRef.current.api.exportDataAsExcel()}
                        className={`${FILE_DETAILS_DOWNLOAD}`}
                    >
                        <p>{DOWNLOAD_RESULTS} </p>
                        <Icon name="ArrowDownwardIcon" />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <div className={`${AG_THEME_ALPINE} ${FILE_DETAILS_GRID_CLASS}`}>
                        <AgGridReact
                            rowData={rowData}
                            ref={gridRef}
                            columnDefs={FILE_DETAILS_COLUMN}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    )
};

const mapStateToProps = (state) => {
    const { file_details, component_input } = state
    return { file_details, component_input };
};

const mapActionToProps = {
    getData: fileDetailsAction
};

export default connect(mapStateToProps, mapActionToProps)(Inputfilesearchresults);