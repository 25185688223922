import {
  FETCHING_DATA,
  MEMBERSHIP_API,
  DEPENDENT_API,
} from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const getPrimarySubscriberAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: { apiData: APIS.find(({ NAME }) => NAME === MEMBERSHIP_API) },
    });
    await apiAction(dispatch, MEMBERSHIP_API, payload);
  };
};

export const getDependentAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: { apiData: APIS.find(({ NAME }) => NAME === DEPENDENT_API) },
    });
    await apiAction(dispatch, DEPENDENT_API, payload);
  };
};
