import { API_ERR, FETCHING_DATA } from '../../Constants/actionTypes';

export const apiReducer = (state = null, action) => {
  // console.log("selected user --->", action)
  switch (action.type) {
    case API_ERR:
      return action.payload.apiData;
    case FETCHING_DATA:
      return action.payload.apiData;
    default:
      return state;
  }
};
