import api from '../../../Utils/Common/api.util';
import { API_ERR } from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { emptyObject } from '../../../Utils/Common/common.util';
export const apiAction = async (
  dispatch,
  apiName,
  payload = {},
  params = {},
  uriParam = {},
) => {
  const apiDetails = APIS.find(({ NAME }) => NAME === apiName);
  if (!apiDetails)
    return dispatch({
      type: API_ERR,
      payload: { error: { msg: 'Invalid API' } },
    });
  try {
    if (!emptyObject(payload)) apiDetails.PAYLOAD = payload;
    if (!emptyObject(params)) apiDetails.PARAM = params;
    if (!emptyObject(uriParam)) {
      const params = Object.keys(uriParam);
      if (params.length > 0) {
        params.forEach((param) => {
          if (uriParam[param])
            apiDetails.PATH = apiDetails.PATH.replace(
              `:${param}`,
              uriParam[param],
            );
        });
      }
    }
    const response = await api(apiDetails);

    apiDetails.RESPONSE = response;
    return dispatch({
      type: apiDetails.AFTER_ACTION,
      payload: response,
    });
  } catch (err) {
    console.error(err);
    return dispatch({
      type: apiDetails.AFTER_ACTION,
      payload: { error: err.message },
    });
  }
};
