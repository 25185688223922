import React from "react";
import { Grid } from "@mui/material";
import '../../Assets/Css/Footer.css';
import Logo from "../../Assets/Images/footer-logo.svg";
const Footer = () => {
  return (
    <div
      style={{ height: 210, backgroundColor: "#035c67", alignItems: 'flex-end' }}
      className="container"
      item
      xs={12}
    >
      <img src={Logo} alt="header-logo" height={30}  style={{marginBottom: '30px'}}/>
      <div className="footer-divider"></div>
      <div className="footer-links">
        <a href="https://www.evernorth.com/terms-of-use">Terms of Use</a> |
         <a href="https://www.evernorth.com/privacy-policy">Privacy Policy</a> |
         <a href="https://www.evernorth.com/notice-of-privacy-practices">Notice of Privacy Practices</a> |
         <a href="https://www.evernorth.com/accessibility">Accessibility</a>  |
         <a href="https://www.evernorth.com/report-fraud">Health Care Fraud</a> |
         <a href="https://www.evernorth.com/nondiscrimination">Nondiscrimination Notice</a> |
         {/* <a href="https://www.evernorth.com/washington-consumer-health-privacy-notice">U.S. State Law Privacy Notice Summit</a> | */}
        <a href="https://www.evernorth.com/washington-consumer-health-privacy-notice">WA Consumer Health Data privacy Notice</a> |
         <a href="https://www.evernorth.com/evernorth-privacy-notices">View All Evernorth Health Privacy Notices</a>
      </div>
    </div>
  );
};

export default Footer;
