import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Modal, Box, Typography, IconButton } from '@mui/material';
import Icon from "./Icon"
import "../../Assets/Css/BasicSearchScreen.css";
import "../../Assets/Css/EligibilityErrorTrends.css"

const Model = ({ open, handleClose, title, content, onConfirmComplete, showButtons, viewList, onItemClick, modalPosition, positions }) => {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: positions ? '45%' : '20%',
    transform: 'translate(-50%, -50%)',
    width: '230px',
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    padding: '10px',
  };
  const hoverStyle = { content: '">"', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', fontSize: '18px', color: '#333', };
  const [hovered, setHovered] = useState(null);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ ...modalStyle }}>
        <Typography id="modal-title" className="view-title">
          {title}
        </Typography>
        <List>
          {viewList && viewList.length > 0 && viewList.map((item, index) => (
            <ListItem
              className={`list-item ${hovered === index ? 'list-item-hovered' : ''}`}
              button
              key={index}
              onClick={() => { onItemClick(item) }}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
            >
              <p className="view-list-item">
                {item}
              </p>
            </ListItem>
          ))}
        </List>
        {showButtons && (
          <div className="button-group">
            <button className={`view-all-error`} onClick={onConfirmComplete}>ok</button>
            <button onClick={handleClose}>Cancel</button>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default Model;