import React, { useState, useEffect } from 'react';
import { connect } from "react-redux"
import {
    saveAdminUsersActions,
    getAdminUsersActions,
    getUserTpaDataActions,
    getTpaDataActions,
    saveTpaDataActions,
    saveUserTpaDataActions,
    // getTpaIdsActions
} from '../../../Redux/Actions/Admin/adminAction';
import DropdownWithMultiselect from "./DropdownWithMultiselect";

const SecuritySettings = (props) => {
    const {
        admin_user,
        saveAdminUsers,
        getAdminUsers,
        get_tpa_ids
    } = props

    useEffect(() => {
        getAdminUsers()
        // getTpaId()
    }, []);

    
    return (
        <DropdownWithMultiselect 
            admin_user={admin_user}
            saveAdminUsers={saveAdminUsers}
            getAdminUsers={getAdminUsers}
            get_tpa_ids={get_tpa_ids}
        />   
    );
};


const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    getAdminUsers: getAdminUsersActions,
    saveAdminUsers: saveAdminUsersActions,
    getUsersTPA: getUserTpaDataActions,
    getTPA: getTpaDataActions,
    saveTPA: saveTpaDataActions,
    saveUserTPA: saveUserTpaDataActions,
    // getTpaId: getTpaIdsActions
};

export default connect(mapStateToProps, mapActionToProps)(SecuritySettings);