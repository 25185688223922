import React from "react"
import {ACTIVITY_EVENTS} from "../../Constants/constants"
const CheckAppIntivity = () =>{
    var secondsSinceLastActivity = 0;
  var maxInactivity = 10; // in sec
  const activity = () => {
    // console.log('active');
    secondsSinceLastActivity = 0;
  };

  const activityWatcher = React.useCallback(() => {
    setInterval(() => {
      secondsSinceLastActivity++;
      if (secondsSinceLastActivity > maxInactivity) {
        secondsSinceLastActivity = 0;
        console.log('app inactive');
      }
    }, 1000);

    ACTIVITY_EVENTS.forEach((eventName) => {
      document.addEventListener(eventName, activity, true);
    });
  }, []);
  React.useEffect(() => {
    activityWatcher();
    return () => {
      console.log('cleanup watcher');
      ACTIVITY_EVENTS.forEach((eventName) => {
        document.removeEventListener(eventName, activity, true);
      });
    };
  }, [activityWatcher]);
    return <></>
}

export default CheckAppIntivity;