import React from "react"
import { Card } from "@mui/material"
import WidgetHeader from "./WidgetHeader"
import WidgetBody from "./WidgetBody"
import WIDGET_COMPONENT from "../../Constants/widgetConstant"
const WidgetCard = (props) => {
    const { handleClose, currIndex, isDisable, close, currentElement } = props
    return (<Card>
        <WidgetHeader
            {...WIDGET_COMPONENT[currentElement?.component]}
            handleClose={() => handleClose({ currIndex, currentElement })}
            currIndex={currIndex}
            isDisable={isDisable}
            close={close} />
        <WidgetBody
            close={close}
            currIndex={currIndex}
            {...WIDGET_COMPONENT[currentElement?.component]}
            currentElement={currentElement} />
    </Card>)
}

export default WidgetCard