import React from 'react';
import { Modal, Box, Typography, Grid } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Icon from "../../Common/Icon";
import { MEMBERSHIP_TRACKING_MODAL } from "../../../Constants/ComponentConstants/componentConstants";
import { modalStyle } from "../../../Constants/constants";

const productModalStyle = { ...modalStyle, width: 1150 }
const TrackingModal = (props) => {
  const { open, close, trackingInfo } = props;
  const { TITLE: { TRACKING } } = MEMBERSHIP_TRACKING_MODAL;


  const columnDefs = [
    { headerName: "Group_ID", field: "Group_ID", sortable: true, filter: true },
    { headerName: "Organization", field: "Organization", sortable: true, filter: true },
    { headerName: "Comp(?)", field: "Comp", sortable: true, filter: true },
    { headerName: "Primary Participant", field: "primary_participant", sortable: true, filter: true },
    { headerName: "Part ID", field: "part_id", sortable: true, filter: true },
    { headerName: "SSN", field: "ssn", sortable: true, filter: true },
    { headerName: "User Id", field: "user_id", sortable: true, filter: true },
    { headerName: "Modified Time", field: "modified_time", sortable: true, filter: true },

  ];


  const rowData = trackingInfo || [];

  return (
    <Modal
      open={open}
      onClose={() => close(false)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      fullWidth
      sx={{ '& .MuiModal-paper': { width: '300px', maxHeight: '80%' } }} 
    >
      <Box sx={productModalStyle}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Typography id="modal-title" variant="h6" component="h6">
              <b>{TRACKING}</b>
            </Typography>
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right', cursor: 'pointer' }}>
            <Icon name="CloseIcon" onClick={() => close(false)} />
          </Grid>
          <Grid item xs={12} className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
            {rowData.length > 0 ? (
              <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                pagination={true}
                paginationPageSize={10}
                domLayout='autoHeight' 
              />
            ) : (
              <Typography variant="body1" style={{ padding: '16px' }}>
                No tracking information found.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TrackingModal;