import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import "../../../Assets/Css/SubscriberDetails.css"
import "../../../Assets/Css/UserInformationScreen.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import MembershipGrid from '../../../Component/Membership/Details/MembershipGrid';
import { Grid } from "@mui/material";
import { eligibilityErrorResultsAction } from '../../../Redux/Actions/Errorflow/eligibilityErrorResultsAction';

import { MEMBER_INFORMATION, MEMBER_INFORMATION1, CLIENT_INFORMATION_COLUMN, EMPLOYER_GROUP_INFORMATION_COLUMN, ERROR_INFO_COLUMN, PRODUCT_INFO_COLUMN }
  from '../../../Constants/ComponentConstants/errorDetailsConstants';


const ErrorDetails = (props) => {
  const { id } = useParams();
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const onGridReady = (params) => {
    gridRef.current = params.api;
  }
  const defaultCofDef = {
    flex: 1,
    minWidth: 10,
    suppressMenu: true,
    headerClass: 'header-left-align',
  };


  const getDataById = (id) => {
    const data = props?.eligerror_result;
    const results = data.find((item) => item._id === (id));
    return results;
  }

  useEffect(() => {
    const datas = getDataById(id);
    setRowData([datas]);
  }, [id]);
  if (!rowData) {
    return <div>Loading...</div>
  }
  const getProductsDataById = () => {
    const flattenedData = props?.eligerror_result.flatMap(item =>
      item.products.map(product => ({
        item: item._id,
        productId: product.productId,
        decription: product.decription,
        effectiveDate: product.effectiveDate,
        terminationDate: product.terminationDate,
        status: product.status,
        enterpriseId: product.enterpriseId,
      }))
    );
    const results = flattenedData.filter(product => {
      return String(product.item) === String(id);
    });

    return results;
  }

  useEffect(() => {
    const datas = getProductsDataById();
    setData(datas);
  }, [id])
  const getErrorsDataById = () => {
    const flattenedData = props?.eligerror_result.flatMap(item =>
      item.errors.map(error => ({
        item: item._id,
        errorType: error.errorType,
        errorDecription: error.errorMessage,
        status: error.errorStatus,
        assigneeId: error.assigneeId,
        assigneeName: error.assigneeName
      }))
    );
    const results = flattenedData.filter(product => {
      return String(product.item) === String(id);
    });

    return results;
  }
  useEffect(() => {
    const datas = getErrorsDataById();
    setErrorData(datas);
  }, [id])

  return (
    <>
      <h1 className="searchtitle">Error Details</h1>
      <div className="line"></div>
      <Grid className="pb-35" container spacing={2}>
        <MembershipGrid
          onGridReady={onGridReady}
          data={rowData}
          title="Client Information"
          column={CLIENT_INFORMATION_COLUMN}
          gridRef={gridRef}
          defaultCofDef={defaultCofDef}
        />
        <MembershipGrid
          onGridReady={onGridReady}
          data={rowData}
          title="Employer Information"
          column={EMPLOYER_GROUP_INFORMATION_COLUMN}
          gridRef={gridRef}
          defaultCofDef={defaultCofDef}
        />
        <MembershipGrid
          onGridReady={onGridReady}
          data={rowData}
          title="Member Information"
          column={MEMBER_INFORMATION}
          gridRef={gridRef}
          defaultCofDef={defaultCofDef}
        />
        <MembershipGrid
          onGridReady={onGridReady}
          data={rowData}
          title=""
          column={MEMBER_INFORMATION1}
          gridRef={gridRef}
          defaultCofDef={defaultCofDef}
        />
        <MembershipGrid
          onGridReady={onGridReady}
          data={errorData}
          title="Error  Record Details"
          column={ERROR_INFO_COLUMN}
          gridRef={gridRef}
          defaultCofDef={defaultCofDef}
          domLayout='autoHeight'
        />
        <MembershipGrid
          onGridReady={onGridReady}
          data={data}
          title="Products"
          column={PRODUCT_INFO_COLUMN}
          gridRef={gridRef}
          defaultCofDef={defaultCofDef}
          domLayout='autoHeight'
        />
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  errorResultsData: eligibilityErrorResultsAction,
};

export default connect(mapStateToProps, mapActionToProps)(ErrorDetails);