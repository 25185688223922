import { ELIGIBILITY_ERRORS_MTD_API } from '../../../Constants/actionTypes';

export const eligibilityErrorsMTDReducer = (state = null, action) => {
  switch (action.type) {
    case ELIGIBILITY_ERRORS_MTD_API:
      return action.payload;
    default:
      return state;
  }
};
