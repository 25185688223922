import React from "react"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import {
    Alert,
    AlertTitle,
    Button,
    Grid,
    Modal,
} from "@mui/material";

const UnAssignedErrors = (props) => {
    const [data, setData] = React.useState('');
    // const [loading, setLoading] = useState(true);
    // const [selectedValue, setSelectedValue] = useState('');
    const navigate = useNavigate();

    // useEffect(() => {
    //     axios.get('https://api.example.com/data')
    //         .then(response => {
    //             setData(response.data); 
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             console.error('Error fetching data:', error);
    //             setLoading(false);
    //         });
    // }, []);


    const handleChange = (event) => {
        setData(event.target.value);
    };

    const handleButtonClick = () => {
        navigate('/results', { state: { selectedValue } });
    };

    return (<>        
        <p id={`admin-security-setup-p-${props.currIndex}`}>
            <a id={`admin-security-setup-a-${props.currIndex}`}
                className={`widget-anchor`} href="#">
            </a>
        </p>
        <p>Select by Payer </p>
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>

                    {/* {data.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))} */}

                </Select>

                {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}
                    disabled={!selectedValue}
                >
                    Go to Results
            </Button> */}

            </FormControl>
        </Box>
        <Grid item xs={12} className="eligibility-error-btn">
            <Button className={`view-all-error`} variant="contained" color="success">
                Assign fallouts
            </Button>
        </Grid>
    </>)
}

export default UnAssignedErrors;