import {
  FETCHING_DATA,
  PAYER_FILE_RATIO_MTD_API,
} from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const payerErrorFileRatioMTDAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === PAYER_FILE_RATIO_MTD_API),
      },
    });
    await apiAction(dispatch, PAYER_FILE_RATIO_MTD_API, payload);
  };
};
