import InfoIcon from '@mui/icons-material/Info';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MessageIcon from '@mui/icons-material/Message';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';

export const icons = {
  InfoIcon: InfoIcon,
  ArrowDownwardIcon: ArrowDownwardIcon,
  CloseIcon: CloseIcon,
  FileDownloadOutlinedIcon: FileDownloadOutlinedIcon,
  PeopleOutlinedIcon: PeopleOutlinedIcon,
  TopicOutlinedIcon: TopicOutlinedIcon,
  ArticleOutlinedIcon: ArticleOutlinedIcon,
  PendingActionsOutlinedIcon: PendingActionsOutlinedIcon,
  DescriptionOutlinedIcon: DescriptionOutlinedIcon,
  PlaylistAddCheckIcon: PlaylistAddCheckIcon,
  HelpOutlineIcon: HelpOutlineIcon,
  MessageIcon: MessageIcon,
  PermIdentityOutlinedIcon: PermIdentityOutlinedIcon,
  KeyboardReturnIcon: KeyboardReturnIcon,
  RefreshIcon: RefreshIcon,
  SaveIcon: SaveIcon,
  EditIcon: EditIcon,
  HighlightOffRoundedIcon: HighlightOffRoundedIcon,
  CalendarTodayOutlinedIcon: CalendarTodayOutlinedIcon,
  RemoveIcon: RemoveIcon,
  AddIcon: AddIcon,
  LockOutlinedIcon: LockOutlinedIcon,
  ArrowOutwardIcon: ArrowOutwardIcon,
  ManageAccountsOutlinedIcon: ManageAccountsOutlinedIcon,
  ArrowForwardOutlinedIcon: ArrowForwardOutlinedIcon,
  DeleteIcon: DeleteIcon,
  DoneIcon: DoneIcon,
  InsertDriveFileOutlinedIcon: InsertDriveFileOutlinedIcon,
};

export const ICON_CONSTANT = {
  INSERT_DRIVE_FILE_OUTLINED_ICON: 'InsertDriveFileOutlinedIcon',
};

export const iconName = Object.keys(icons);
