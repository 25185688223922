import React from "react"
import "../../Assets/Css/CommonWidget.css";
import DashboardGrid from "./DashboardGrid"
const DashboardGridContainer = (props) => {

    return (<>
        <DashboardGrid {...props} i={0} />
        <DashboardGrid {...props} i={1} />
        <DashboardGrid {...props} i={2} />
    </>)
}


export default DashboardGridContainer
