import React, { useState, useMemo, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import '../../../Assets/Css/FileTracking.css'
import { fileTrackingColumnDef } from "../../../Utils/Widget/PayerEligibilityFileTracking/fileTracking.util"
import { fileTrackingAction } from "../../../Redux/Actions/Widgets/fileTrackingAction"
import { connect } from "react-redux";
import { ROUTES } from "../../../Constants/routesConstant"
import { useNavigate, useLocation } from "react-router-dom";
import {
  TITLE,
  CLASS_NAME,
  PAGINATION
} from "../../../Constants/ComponentConstants/WidgetConstants/PayerEligibilityFileTrackingConstants/fileTrackingConstants"
import { COMMON_CLASS, COMMON_TEXT, AG_COLUMN_CENTER } from "../../../Constants/constants"
import { ICON_CONSTANT } from "../../../Constants/iconsConstant"
import Icon from "../../Common/Icon"
import "../../../Assets/Css/Breadcrumbs.css"
import IconButton from '@mui/material/IconButton';
import { Box, Typography } from '@mui/material';
import FileStatusRender from "./FileStatusRender"
import GotoFileDetails from "./GotoFileDetails"

const Filetracking = (props) => {
  const [rowData, setRowData] = useState([]);
  const { getData, file_tracking: { fileTracking } } = props
  const {
    SEARCH_TITLE,
    LINE,
    FILE_TRACKING_GRID_CLASS
  } = CLASS_NAME
  const { AG_THEME_ALPINE, CURSOR_POINTER } = COMMON_CLASS
  const { CUSTOM } = COMMON_TEXT
  const { FILE_SEARCH } = ROUTES
  const { FILE_TRACKING } = TITLE
  const { INSERT_DRIVE_FILE_OUTLINED_ICON } = ICON_CONSTANT
  const navigate = useNavigate();
  const location = useLocation();
  const gridRef = useRef(null);
  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (fileTracking?.length > 0)
      setRowData(fileTracking?.map(file => {
        file.payerName = file?.payerName?.toUpperCase()
        return file
      }))
  }, [fileTracking])

  const goToFile = (param = {}) => {
    navigate(FILE_SEARCH, { ...param, state: { from: location.pathname } })
  }


  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 120,
      display: "flex",
      alignItems: "center",
      textAlign: "center"
    };
  }, []);
  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
      groupSelects: "descendants",
      headerCheckbox: false,
      checkboxes: false,
    };
  }, []);
  const exportToExcel = () => {

    gridRef.current.api.exportDataAsExcel({
      fileName: 'FileTrackingData.xlsx',

    });
  };
  return (
    <>
      <h1 className={`${SEARCH_TITLE}`}>{FILE_TRACKING}</h1>

      <div className={`${LINE}`}></div>
      <div className={`${AG_THEME_ALPINE} ${FILE_TRACKING_GRID_CLASS}`}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>

          </Box>
          <IconButton onClick={exportToExcel} sx={{ color: 'blue', fontSize: 15, border: 'none', outline: 'none', background: 'none', borderRadius: 0 }}>
            <Typography variant="body2" sx={{ margin: 0 }}>Download Excel</Typography>
            <Icon name="ArrowDownwardIcon" />
          </IconButton>
        </Box>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={fileTrackingColumnDef({
            AG_COLUMN_CENTER,
            FileStatusRender,
            GotoFileDetails,
            rowData,
            goToFile,
            INSERT_DRIVE_FILE_OUTLINED_ICON,
            CURSOR_POINTER
          })}
          defaultColDef={defaultColDef}
          groupDisplayType={CUSTOM}
          groupDefaultExpanded={0}
          rowSelection={rowSelection}
          pagination={true}
          paginationPageSize={10}
          paginationPageSizeSelector={PAGINATION}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { file_tracking } = state
  return { file_tracking };
};

const mapActionToProps = {
  getData: fileTrackingAction
};

export default connect(mapStateToProps, mapActionToProps)(Filetracking);
