import React from "react"
import { Grid, Button, Typography } from "@mui/material";
import { DASHBOARD_TEXT } from "../../Constants/ComponentConstants/componentConstants"
import DashboardGridContainer from "./DashboardGridContainer";
import { connect } from "react-redux";
import { inputAction } from "../../Redux/Actions/Common/inputAction"
import Icon from "../Common/Icon"
import "../../Assets/Css/Dashboard.css";
import { WIDGET_LIST, EXCLUDE_WIDGET } from "../../Constants/widgetConstant"
import {
  saveDashboardSettingAction,
  getDashboardSettingAction
} from "../../Redux/Actions/Dashboard/dashboardSettingsAction";
import {
  refineWidgetList,
  getDashboardWidget,
  getDashboardSavePayload,
  setDropItem,
  removeWidget,
  calculateEachRowHeight,
  getInactiveWidgetList
} from "../../Utils/Dashboard/dashboard.util"

const Dashboard = (props) => {
  const {
    input,
    component_input,
    okta_token,
    getDashboardSettings,
    saveDashboardSettings,
    dashboard_settings
  } = props;
  const [currentIndex, setCurrentIndex] = React.useState(-1)
  const [draggedElement, setDraggedElement] = React.useState()
  const [mouseLocation, setMouseLocation] = React.useState({ index: -1, position: '' })
  const [inactiveWidget, setInactiveWidget] = React.useState([])
  const [editMode, setEditMode] = React.useState(false)
  const [rowsHeight, setRowHeight] = React.useState([])
  const increaseZoomLevel = 1 - ((window.outerWidth - 10) / window.innerWidth)
  const columnWidth = [window.innerWidth / 3, 2 * window.innerWidth / 3, window.innerWidth]
  const [defaultItem, setDefaultItem] = React.useState([])

  const setActiveWidget = (item = []) => {
    input({ ...props.component_input, 'activeWidget': item?.length === 0 ? item : [...[], ...refineWidgetList(item)] })
  }
  const getWidget = async (showDashboard = true) => {
    await getDashboardSettings({ cid: okta_token?.claims?.cid, uid: okta_token?.claims?.lanID })
    showWidget(showDashboard)
  }
  const showWidget = (showDashboard = true) => {
    if (dashboard_settings?.widgets?.length > 0) {
      const dashboardWidgets = getDashboardWidget(dashboard_settings)
      setDefaultItem(dashboardWidgets)
      if (showDashboard) setActiveWidget(dashboardWidgets)
    }
  }
  const saveWidget = async () => {
    const { activeWidget } = component_input
    const refineActiveWidget = refineWidgetList(activeWidget)
    setActiveWidget(refineActiveWidget)
    if (!editMode) return;
    const payload = getDashboardSavePayload(okta_token, refineActiveWidget)
    await saveDashboardSettings(payload)
    await getWidget(false)
  }
  const handleOnStart = (elm, elementParentsIndex = 0) => {
    setCurrentIndex(elementParentsIndex)
    setDraggedElement(elm)
  }


  const handleOnStop = (e) => {
    const item = refineWidgetList(component_input?.activeWidget)
    const finalDropElement = setDropItem({
      e,
      draggedElement,
      item,
      mouseLocation,
      currentIndex,
      rowsHeight,
      columnWidth
    })
    setActiveWidget(finalDropElement)
  }

  const handleClose = (data) => {
    const item = removeWidget(data, refineWidgetList(component_input?.activeWidget))
    setActiveWidget(item)
  }
  const resetToDefault = () => {
    setActiveWidget(defaultItem)
  }
  const setInactive = (activeWidgetsList) => {
    const inactiveWidgetList = []
    setInactiveWidget(inactiveWidgetList)
    setInactiveWidget([...[], ...getInactiveWidgetList(inactiveWidgetList, activeWidgetsList, WIDGET_LIST)])
  }


  React.useEffect(() => {
    const activeWidgetsList = (component_input?.activeWidget || []).map(e => e?.component)
    setInactive(activeWidgetsList)
    calculateEachRowHeight(activeWidgetsList, increaseZoomLevel, setRowHeight);
  }, [component_input?.activeWidget])
  React.useEffect(() => {
    getWidget();
  }, [])
  React.useEffect(() => {
    showWidget()
  }, [dashboard_settings, EXCLUDE_WIDGET])
  return (
    <>
      <Grid
        container
        className={`dashboard-sub-header-container`}
        columns={{ xs: 4, md: 12 }}
        spacing={2}>
        <Grid className={'dashboard-title title-border'} item xs={7}>
          <Typography
            variant="h4"
            align="left"
            gutterBottom
          >
            {DASHBOARD_TEXT.TITLE}
          </Typography>
        </Grid>
        <Grid className={`dashboard-btn title-border`} style={{ textAlign: 'right' }} item xs={5}>
          <div className={`dashboard-left-menu`}>
            {editMode ?
              <Button className={`grid-edit-btn`} onClick={() => resetToDefault()}>
                {DASHBOARD_TEXT.RESET_DASHBOARD}
              </Button> : <></>}
            <Button className={`grid-edit-btn`} onClick={() => { saveWidget(); setEditMode(!editMode); }}>
              {editMode ?
                <>{DASHBOARD_TEXT.SAVE_DASHBOARD} <Icon name="SaveIcon" /></> :
                <>{DASHBOARD_TEXT.EDIT_DASHBOARD}<Icon name="EditIcon" /></>}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        className={`active-widget-area`}
        id={`active-widget-area`}
        columns={{ xs: 4, md: 12 }}>
        <DashboardGridContainer
          idStartWith={`active-widget`}
          id={`active-widget-container`}
          activeWidget={refineWidgetList(component_input?.activeWidget) || []}
          handleOnStart={handleOnStart}
          handleOnStop={handleOnStop}
          setMouseLocation={setMouseLocation}
          isDisable={!editMode}
          close={true}
          handleClose={handleClose}
        />
      </Grid>
      {editMode ?
        <Grid
          container
          id={`inactive-widget-area`}
          className={`inactive-widget-area`}
        >
          <Typography
            variant="h4"
            gutterBottom
          >{DASHBOARD_TEXT.ADD_WIDGET}</Typography>
          <Grid container
            spacing={2}
            columns={{ xs: 4, md: 12 }}>
            <DashboardGridContainer
              idStartWith={`inactive-widget`}
              activeWidget={refineWidgetList(inactiveWidget) || []}
              handleOnStart={handleOnStart}
              handleOnStop={handleOnStop}
              setMouseLocation={setMouseLocation}
              isDisable={false}
              close={false}
            />
          </Grid>
        </Grid> :
        <></>}
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  input: inputAction,
  saveDashboardSettings: saveDashboardSettingAction,
  getDashboardSettings: getDashboardSettingAction
};

export default connect(mapStateToProps, mapActionToProps)(Dashboard);
