import { TOKEN_GENERATION_API } from '../../Constants/actionTypes';

export const tokenGenerationReducer = (state = null, action) => {
  switch (action.type) {
    case TOKEN_GENERATION_API:
      localStorage.access_token = action.payload?.access_token || '';
      return action.payload;
    default:
      return state;
  }
};
