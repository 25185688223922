import React from 'react';
import "../../../Assets/Css/SearchBox.css";
import MembershipSearchResults from './MembershipSearchResults';

const SearchResults = () => {
    return (
        <>
            <MembershipSearchResults />
        </>
    );
};

export default SearchResults;