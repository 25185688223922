import { SELECTED_USER } from '../../../Constants/actionTypes';
export const selectedUserReducer = (state = null, action) => {
  // console.log("selected user --->", action)
  switch (action.type) {
    case SELECTED_USER:
      return action.payload.user;
    default:
      return state;
  }
};
