import { REPLACE_WORD, CAPS_TO_SPACE } from '../../Constants/constants';
export const underscoreToCamel = (str = '') => {
  return str
    .split('_')
    .map((string) => string.charAt(0).toUpperCase() + string.slice(1))
    .join(' ');
};

export const underscoreToFirstCharCapital = (str = '') => {
  return str
    .split('_')
    .map((string, i) => {
      if (i === 0) return string.charAt(0).toUpperCase() + string.slice(1);
      else return string;
    })
    .join(' ');
};

export const emptyObject = (obj = {}) => {
  return Object?.keys(obj)?.length === 0 ? true : false;
};
export const getArrayPosition = (arr, findnumber) => {
  let rowNumber = -1;
  arr?.forEach((h, i) => {
    const prevValue = i > 0 ? arr[i - 1] : 0;
    if (
      (findnumber > prevValue && findnumber < h) ||
      (i === arr.length - 1 && findnumber > h)
    )
      rowNumber = i;
  });
  return rowNumber;
};

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};
export const capitalizeFirstLetter = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const addSpaceBeforeCaps = (str = '') => {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    if (char === char.toUpperCase() && i !== 0) {
      result += ' ' + char;
    } else {
      result += char;
    }
  }
  return result;
};

export const emptyObjectWithEmptyKeys = (obj = {}) => {
  if (emptyObject(obj)) return true;
  else {
    let isEmptyKey = true;
    Object?.keys(obj)?.forEach((key) => {
      if (obj[key]) isEmptyKey = false;
    });
    return isEmptyKey;
  }
};

export const splitFullName = (fullName = '') => {
  const nameParts = fullName.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts[nameParts.length - 1];

  let middleName = '';
  if (nameParts.length > 2) {
    middleName = nameParts.slice(1, -1).join(' ');
  }

  return {
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
  };
};

const getText = (str = '') => {
  let returnTxt = str;
  Object.keys(REPLACE_WORD).forEach((txt) => {
    if (txt === str) returnTxt = REPLACE_WORD[txt];
  });
  return returnTxt;
};

export const camelCaseToWords = (str = '') => {
  if (CAPS_TO_SPACE.some((word) => word === str)) return str;
  const result = str.replace(/([A-Z])/g, ' $1');
  return (
    result.charAt(0).toUpperCase() +
    result
      .slice(1)
      .split(' ')
      .map((word) => getText(word))
      .join(' ')
  );
};

export const removeSpecialChars = (str = '') => {
  return str.replace(/[^a-zA-Z0-9 ]/g, '');
};

export const isObject = (object = {}) => {
  return object.constructor === Object;
};
export const lowercaseFirstLetter = (str = '') => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};
