import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import {RadioGroup, FormControlLabel, FormControl} from '@mui/material';

const RadioButtonRenderer = (params) => {
    const { value, colDef } = params;

    const handleChange = (event) => {
        const newValue = event.target.value;
        params.node.setDataValue(colDef.field, newValue);
    };    

    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" disabled={!params.isEditable}/>
                <FormControlLabel value={"No"} control={<Radio />} label="No" disabled={!params.isEditable}/>
            </RadioGroup>
        </FormControl>)
}

export default RadioButtonRenderer;