import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { connect } from "react-redux";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../../Assets/Css/EligibilityErrorTrends.css"
import { useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Icon from "../../Common/Icon";
import IconButton from '@mui/material/IconButton';
import widgetConstant from '../../../Constants/widgetConstant';
import { errorTrendsMTDAction } from '../../../Redux/Actions/Widgets/errorTrendsMTDAction';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Eligibilityerrortrends = (props) => {
    const items = ['TODAY', 'Last Business Day', 'MTD', 'Previous Month', 'QTD', 'Previous Quarter', 'YTD']
    const [dropDownVisible, setDropDownVisible] = useState(false);
    //const [data, setData] = useState([])
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [showColumnMenu, setShowColumnMenu] = useState(false);
    const [previousVisibleColumns, setPreviousVisibleColumns] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedName, setSelectedName] = useState(items[0]);

    const navigate = useNavigate();
    const query = useQuery();
    const gridRef = useRef(null);
    const gridApiRef = useRef(null);
    const columnMenuRef = useRef(null);
  
    const datas = [{
        Payername: 'Group Administrators, LTD',
        Frontenderrors: '5%(123)',
        Backenderrors: '10%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',

    },
    {
        Payername: 'The Lewer Agency, INC',
        Frontenderrors: '16%(123)',
        Backenderrors: '7%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Capital Administrators SE',
        Frontenderrors: '9%(123)',
        Backenderrors: '23%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Key Benifit Administrators, INC',
        Frontenderrors: '24%(123)',
        Backenderrors: '32%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Lucent Health California Division',
        Frontenderrors: '8%(123)',
        Backenderrors: '11%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Health scope Benifits',
        Frontenderrors: '66%(123)',
        Backenderrors: '21%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'American Republic Insurance Company',
        Frontenderrors: '46%(123)',
        Backenderrors: '56%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Allegiance, Benifit Plan Management, INC',
        Frontenderrors: '2%(123)',
        Backenderrors: '6%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'EBIX Health Administration, INC',
        Frontenderrors: '9%(123)',
        Backenderrors: '8%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Ameriben/IEC group',
        Frontenderrors: '3%(123)',
        Backenderrors: '10%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Assurant Health -Best',
        Frontenderrors: '65%(123)',
        Backenderrors: '24%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Assurant Health-Aces',
        Frontenderrors: '12%(123)',
        Backenderrors: '16%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Health Smart West',
        Frontenderrors: '31%(123)',
        Backenderrors: '25%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Meritain DG ',
        Frontenderrors: '4%(123)',
        Backenderrors: '15%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'South Point Benifits',
        Frontenderrors: '5%(123)',
        Backenderrors: '22%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Health Smart west',
        Frontenderrors: '17%(123)',
        Backenderrors: '10%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'Health Scope staff',
        Frontenderrors: '5%(123)',
        Backenderrors: '25%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    },
    {
        Payername: 'KBA/Assurant self-funded',
        Frontenderrors: '5%(123)',
        Backenderrors: '10%(246)',
        Totalfallouts: '650',
        Filerejections: '72',
        Gatewayrejections: '34',
    }
    ]
    const handleItemClick = async (itemId) => {
        setSelectedItem(itemId);
        setSelectedName(itemId);
        setDropDownVisible(false);
    };
    const handleClick = (e) => {
        setDropDownVisible(!dropDownVisible);
    };
    const [columnDeffs, setcolumnDeffs] = useState([
        {
            headerName: 'Payer name', field: 'Payername', sortable: true, filter: 'agSetColumnFilter', order: 0, filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'Front end errors', field: 'Frontenderrors', sortable: true, order: 1, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'Back end errors', field: 'Backenderrors', sortable: true, order: 2, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'Total fallouts', field: 'Totalfallouts', sortable: true, order: 3, sort: 'asc', filter: 'agSetColumnFilter',
            filterParams: {
                applyMiniFilterWhileTyping: true,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                closeOnApply: true,
                buttons: ['reset', 'apply'],
            }
        },
        {
            headerName: 'File rejections', field: 'Filerejections', sortable: true, order: 4, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
        {
            headerName: 'Gateway rejections', field: 'Gatewayrejections', sortable: true, order: 5, filter: 'agSetColumnFilter',
            filterParams: {
                debounceMs: 500,
                suppressMiniFilter: false,
                selectAllowMiniFilter: true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            }
        },
    ]);
    const handleApply = () => {
        setShowColumnMenu(false);
    };
    const handleReset = () => {
        const updatedColumns = columnDeffs.map(column => ({
            ...column,
            hide: false
        }));
        setcolumnDeffs(updatedColumns);
        setShowColumnMenu(false);
    };
    useEffect(() => {
        if (gridApi && gridColumnApi) {
            gridColumnApi.getAllColumns().forEach((column) => {
            });
        }
        function handleClickOutside(event) {
            if (columnMenuRef.current && !columnMenuRef.current.contains(event.target)) {
                setShowColumnMenu(false);
            }
        }
        // setData(props?.member_list?.error ? [] : props?.member_list)
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [gridApi, gridColumnApi]);
    const onColumnMoved = () => {
        if (gridRef.current) {
            const allColumnState = gridRef.current.getColumnState();
            const newColumnOrder = allColumnState.map(columnState => columnState.colId); // Use colId for tracking
        }
    };
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        gridRef.current = params.api;
        params.api.addEventListener('columnMoved', onColumnMoved);
        const savedColumnOrder = JSON.parse(localStorage.getItem('columnOrder'));
        if (savedColumnOrder) {
            const newcolumnDeffs = savedColumnOrder.map(field =>
                columnDeffs.find(colDef => colDef.field === field)
            );
            if (params.api.setcolumnDeffs) {
                params.api.setcolumnDeffs(newcolumnDeffs);
            } else {
                console.error("setcolumnDeffs is not available on params.api");
            }
        }
    };
    const toggleColumnMenu = () => {
        setShowColumnMenu(!showColumnMenu);
    };
    const toggleColumn = (columnName, checked) => {
        const newcolumnDeffs = columnDeffs.map(column => {
            if (column.field === columnName) {
                return { ...column, hide: !checked };
            }
            return column;
        });
        setcolumnDeffs(newcolumnDeffs);
    };
    const gridOptions = {
        suppressBorders: true,
        defaultColDef: {
            minWidth: 300,
        },
    };
    const exportDataGridToExcel = (columnDeffs, datas, fileName) => {
        const headers = columnDeffs.filter(col => col.field !== 'Button').map(col => col.headerName);
        const mappedData = datas.map(row => {
            let mappedRow = {};
            columnDeffs.forEach(col => {
                if (col.field !== 'Button') {
                    mappedRow[col.headerName] = row[col.field]
                }
            });

            return mappedRow;

        });
        const worksheet = XLSX.utils.json_to_sheet(mappedData, { header: headers });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, fileName);
    };
    const handleExportExcel = () => {
        const visibleColumns = columnDeffs.filter(col => !col.hide);
        const visibleColumnFields = visibleColumns.map(col => col.headerName);
        const filteredData = datas.map(row => {
            const filteredRow = {};
            visibleColumnFields.forEach(field => {
                if (field !== 'Button') {
                    filteredRow[field] = row[field];
                }
            });
            return filteredRow;
        })
        exportDataGridToExcel(visibleColumns, datas, 'Error Trends.xlsx');//changed filtered data to  data as pointing to mock
    };
    return (
        <>
            <h1 className="searchtitle">Eligibility Errors Trends</h1>
            <div className="line"></div>
            <div className="ag-theme-alpine error-trends">
                <div >
                    <div className="left-buttons">
                        <button className="edit-columns"
                            onClick={toggleColumnMenu}
                        >
                            Edit Columns
                         </button>
                        <button className="mtd-list"
                            onClick={handleClick}
                        >
                            {selectedName}
                        </button>
                        {dropDownVisible &&
                            <ul className="dropdown">
                                {items.map((item, index) => (
                                    <li key={index} className="mtd-dropdown-li-ob" onClick={() => handleItemClick(item)}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        }
                        <IconButton className="download-results" onClick={handleExportExcel}>
                            <p>Download Results </p>
                            <Icon name="ArrowDownwardIcon" className="arrow-icon" />
                        </IconButton>
                    </div>
                </div>
                {showColumnMenu && (
                    <div className="columns">

                        <strong> Columns</strong>
                        {columnDeffs.map((column) => (
                            <div key={column.field} className="column-margin">
                                <label className="checkbox-pointer">
                                    <input
                                        type="checkbox"
                                        checked={!column.hide}
                                        onChange={(e) => toggleColumn(column.field, e.target.checked)}
                                        disabled={column.headerName === 'Actions'}
                                        classNames="column-names"
                                    />
                                    {column.headerName}
                                </label>
                            </div>
                        ))}
                        <div className="edit-columns-buttons">
                            <button onClick={handleReset} className="reset-button" >Reset</button>
                            <button onClick={handleApply} className="apply-button"> Apply </button>
                        </div>
                    </div>
                )}

                <AgGridReact
                    onGridReady={onGridReady}
                    rowData={datas}
                    columnDefs={columnDeffs}
                    gridOptions={gridOptions}
                    onColumnMoved={onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 15, 20, 50]}
                    domLayout='autoHeight'
                    enableColumnHandling
                    ref={(grid) => { gridApiRef.current = grid }}
                />
            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    getMTDData: errorTrendsMTDAction
};

export default connect(mapStateToProps, mapActionToProps)(Eligibilityerrortrends);