import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Grid, Button } from "@mui/material";
import { connect } from "react-redux";
import { ROUTES } from "../../../Constants/routesConstant"
import { useNavigate } from "react-router-dom";
import { payerErrorFileRatioMTDAction } from '../../../Redux/Actions/Widgets/payerErrorFileRatioMTDAction';
import '../../../Assets/Css/EligibilityErrorTrends.css';
import widgetConstant, { MTD_ITEM } from '../../../Constants/widgetConstant';
import { eligibilityErrorResultsAction } from '../../../Redux/Actions/Errorflow/eligibilityErrorResultsAction';

ChartJS.register(ArcElement, Tooltip, Legend);

const MembershipToErrorRatio = (props) => {
  const { getERRRatioMTDData, payererrratio_mtd } = props;
  const [links, setLinks] = React.useState([]);
  const objtoarr = (obj) => {
    console.log('obj->', obj);
    const tempLink = [];
    Object.keys(obj).forEach(elm => {
      if (!tempLink.map(txt => txt.text).includes(elm))
        tempLink.push({ text: elm, value: obj[elm] })
    })
    setLinks([...[], ...tempLink])
  };
  React.useEffect(() => {
    getERRRatioMTDData({ range: props.mtdItem === "" ? MTD_ITEM[4] : props.mtdItem })
  }, [props.mtdItem])
  React.useEffect(() => {
    objtoarr(payererrratio_mtd);
  }, [payererrratio_mtd]);
  let data = [
    {
      value: 48,
      color: "rgba(15, 143, 133, 1)",
      cutout: "80%",
      name: 'Healthscope Benefits'
    },
    {
      value: 24,
      color: "rgba(12, 255, 255, 1)",
      cutout: "80%",
      name: 'Capital Administrators SE'
    },
    {
      value: 12,
      color: "rgba(244, 143, 113, 1)",
      cutout: "80%",
      name: 'sample 3'
    },
    {
      value: 14,
      color: "rgba(189, 194, 219, 1)",
      cutout: "80%",
      name: 'sample 4'
    },
    {
      value: 14,
      color: "rgba(214, 214, 214, 1)",
      cutout: "80%",
      name: 'sample 1'
    },
    {
      value: 14,
      color: "rgba(160, 214, 214, 1)",
      cutout: "80%",
      name: 'sample 1'
    },
    {
      value: 14,
      color: "rgba(214, 255, 214, 1)",
      cutout: "80%",
      name: 'sample 1'
    },
  ]

  const options = {
    plugins: {
      enabled: true,
      responsive: true,
      legend: {
        display: false
      }
    },
    cutout: data.map((item) => item.cutout),
    radius: '80%',
    layout: {
      padding: 0
    }
  };
  const obj = payererrratio_mtd;
  const finalData = {
    labels: links.map((item) => item?.text),
    datasets: [
      {
        // data: data.map((item) => Math.round(item.value)),
        data: links.map((item) => item?.value?.split('%')[0]),
        backgroundColor: data.map((item) => item?.color),
        borderColor: data.map((item) => item?.color),
        borderWidth: 1,
        dataVisibility: new Array(data?.length).fill(true)
      },
    ]
  };
  const navigate = useNavigate();
    const handleViewAllErrors = async () => {
      await props.errorResultsData({});
    navigate(`${ROUTES.ERROR_RESULTS}`);
  };

  return (
    <div className={`membership-to-error-ratio`} id={`current-index-${props.currIndex}`}>
      <div className={`member-title`}>
        <Doughnut data={finalData} options={options} />
      </div>
      {links.length === 0 ? (<div className="no-data">No Data Available</div>) : (
        links.map((link, i) => {
          return (
            <Grid key={`${JSON.stringify(link)}-${i}`} className={`pd-15 current-index-${props.currIndex}`} container>
              <Grid item className={`error-container widget-anchor common-line`} xs={10}>
                <a href="#"
                  className={`widget-anchor`}>
                  {link.text}</a>
              </Grid>
              <Grid item className={`text-right error-container common-line`} xs={2}>{link.value}</Grid>
            </Grid>
          )
        }))}
      <Grid item xs={12} className="eligibility-error-btn">
        <Button className={`view-all-error`} variant="contained" color="success" size="small" onClick={handleViewAllErrors}>
          View all errors
          </Button>
      </Grid>
    </div>
  )
}
const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  getERRRatioMTDData: payerErrorFileRatioMTDAction,
  errorResultsData: eligibilityErrorResultsAction,
};

export default connect(mapStateToProps, mapActionToProps)(MembershipToErrorRatio);