import {
  FETCHING_DATA,
  GET_DASHBOARD_SETTINGS,
  SAVE_DASHBOARD_SETTINGS,
} from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const getDashboardSettingAction = (param = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === GET_DASHBOARD_SETTINGS),
      },
    });
    await apiAction(dispatch, GET_DASHBOARD_SETTINGS, {}, {}, param);
  };
};

export const saveDashboardSettingAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(({ NAME }) => NAME === SAVE_DASHBOARD_SETTINGS),
      },
    });
    await apiAction(dispatch, SAVE_DASHBOARD_SETTINGS, payload);
  };
};
