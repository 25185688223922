import React, { useState } from "react";
import {
    Typography, Grid, Alert,
    AlertTitle
} from "@mui/material";
import Icon from "../Common/Icon"
import widgetConstant, {MTD_ITEM} from '../../Constants/widgetConstant';
import CustomModal from '../Common/CustomModal';
import DraggableElementIcon from "../../Assets/Images/drag-dots-svgrepo-com.svg";

const WidgetHeader = (props) => {
    const [dropDownVisible, setDropDownVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedName, setSelectedName] = useState(MTD_ITEM[4]);
    const [openPopup, setOpenPopup] = useState(false);
    const handleClick = (e) => {
        setDropDownVisible(!dropDownVisible);
    };
    const handleItemClick = async (itemId, title) => {
        widgetConstant[props.componentName].mtdItem = itemId
        setSelectedItem(itemId);
        setSelectedName(itemId);
        setDropDownVisible(false);
    };
    const handleHelp = (props) => {
        if (props.icon === 'InfoIcon') setOpenPopup(true);
    };
    const handlePopupClose = () => {
        setOpenPopup(false);
    };
    const combinations = [
        "lastName, firstName",
        "lastName, firstName, birthDate",
        "lastName"
    ];
    const prepareContent = (combinations) => {
        return (
            <ul>
                {combinations.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        );
    };

    const content = prepareContent(combinations);

    return (<>
     <CustomModal
            open={openPopup}
            handleClose={handlePopupClose}
            title="Basic Search Combinations"
            content={content}
        />
        {!props.isDisable ?
            <Grid container style={{ textAlign: `${props?.close ? 'right' : 'center'}` }}>
                <Grid item xs={props?.close ? 6 : 12}>
                    <img
                        src={DraggableElementIcon}
                        style={{ transform: 'rotate(90deg)' }}
                        height={25}
                        id={`${props.currIndex}-drag-img`}
                        className={`${props.currIndex}-drag-img`}
                        width={25} />
                </Grid>
                {props?.close ?
                    <Grid item xs={6}>
                        <Icon name="HighlightOffRoundedIcon" onClick={props.handleClose} />
                    </Grid> :
                    <></>}
            </Grid> :
            <></>}
        <>{
            !props?.hideHeader ?
                <Grid className={`pd-20  ${props.isDisable ? '' : 'mt--20'} current-index-${props.currIndex}} widget-title`} container>
                    {props.title === "Unassigned errors" && (
                        <div className="widgeterr">
                            <Alert severity="error" >
                                <AlertTitle ></AlertTitle>
                                {props?.errors}
                            </Alert>
                        </div>
                    )}
                    {props.icon && <Grid>
                        <Icon name={props.icon} className="dashboard-icons" sx={{ fontSize: '46px' }} onClick={() => handleHelp(props)} />
                    </Grid>}
                    <Grid item xs={props?.mtd ? 10 : 12}>
                        <Typography
                            variant="h5"
                            align="left"
                            gutterBottom
                            sx={{ marginTop: '10px', position: 'relative' }}
                        >{props.title}</Typography>
                    </Grid>
                    {props?.mtd ?
                        <Grid className={`text-right`} item xs={2}>
                            <div className="mtd" onClick={handleClick}>{selectedName} </div>
                            {dropDownVisible &&
                                <ul className="mtd-dropdown">
                                    {MTD_ITEM.map((item, index) => (
                                        <li key={index} className="mtd-dropdown-li" onClick={() => handleItemClick(item, props.urlTitle)}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            }
                        </Grid> :
                        <></>}
                    {props.subTitle ?
                        <Grid className={`widget-subbtitle`} item xs={12}>
                            <Typography
                                variant="body1"
                                size="small"
                                align="left"
                                gutterBottom
                                sx={{ marginTop: '20px' }}
                            >
                                {props.subTitle}
                            </Typography>
                        </Grid> :
                        <></>}
                </Grid>
                : <><div class={`pd-20  ${props.isDisable ? '' : 'mt--30'} current-index-${props.currIndex}`}>
                </div></>
        }</>

    </>);
}
export default WidgetHeader