import { FILE_STATUS } from '../../../Constants/ComponentConstants/WidgetConstants/PayerEligibilityFileTrackingConstants/fileTrackingConstants';
const { REJECTED, COMPLETED, PROCESSING } = FILE_STATUS;
export const getStatusClassName = (str = '') => {
  switch (str) {
    case REJECTED?.TEXT:
      return REJECTED?.CLASS;
    case COMPLETED?.TEXT:
      return COMPLETED?.CLASS;
    case PROCESSING?.TEXT:
      return PROCESSING?.CLASS;
    default:
      return str;
  }
};

export const getStatus = (rowData = [], key = '') => {
  return rowData?.find(({ payerName, status }) => payerName === key && status)
    ?.status;
};

export const fileTrackingColumnDef = ({
  AG_COLUMN_CENTER,
  FileStatusRender,
  GotoFileDetails,
  rowData,
  goToFile,
  INSERT_DRIVE_FILE_OUTLINED_ICON,
  CURSOR_POINTER,
}) => {
  return [
    {
      headerName: 'Payer Name',
      field: 'payerName',
      showRowGroup: true,
      cellRenderer: 'agGroupCellRenderer',
      minWidth: 400,
      cellClass: 'grid-cell-center',
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 150,
      ...AG_COLUMN_CENTER,
      cellRenderer: FileStatusRender,
      cellRendererParams: {
        rowData: rowData,
      },
    },
    {
      headerName: 'Payer Name',
      field: 'payerName',
      rowGroup: true,
      minWidth: 250,
      hide: true,
    },
    { headerName: 'Date', field: 'date', ...AG_COLUMN_CENTER },
    { headerName: 'File In', field: 'fileIn', ...AG_COLUMN_CENTER },
    { headerName: 'File Out', field: 'fileOut', ...AG_COLUMN_CENTER },
    {
      headerName: 'Records in Input Files',
      field: 'recordsInInputFiles',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records rejected by Gateway',
      field: 'recordsRejectedByGateway',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records errored at DQM',
      field: 'recordsErroredatDqm',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records errored by entities',
      field: 'recordsRejectedByEntities',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'Records Processed',
      field: 'recordsProcessed',
      minWidth: 250,
      ...AG_COLUMN_CENTER,
    },
    {
      headerName: 'File link',
      field: 'fileLink',
      ...AG_COLUMN_CENTER,
      cellRenderer: GotoFileDetails,
      cellRendererParams: {
        goToFile: goToFile,
        icon: INSERT_DRIVE_FILE_OUTLINED_ICON,
        fileClass: CURSOR_POINTER,
      },
    },
  ];
};
