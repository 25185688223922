import { GET_MEMBER_PRODUCTS } from '../../../Constants/actionTypes';

export const membershipProductReducer = (state = null, action) => {
  switch (action.type) {
    case GET_MEMBER_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};
