import { COMPONENT_INPUT } from '../../Constants/actionTypes';

export const inputReducer = (state = null, action) => {
  switch (action.type) {
    case COMPONENT_INPUT:
      return action.payload.input;
    default:
      return state;
  }
};
